<style scoped lang="scss">
.curriculum {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.look-btn {
  width: 60px;
  padding: 0;
  height: 30px;
  line-height: 30px;
  color: #6340c8;
  background-color: #f2edff;
  border-radius: 6px;
  border: solid 1px #c0adf9;

  &:hover {
    color: #ffffff;
    background-color: #6340c8;
    border: 1px solid #6340c8;

    &:before,
    &:after {
      background: #6340c8;
    }
  }
}

.curriculum-search {
  flex: none;
}

.curriculum-table {
  flex: 1;
  overflow: hidden;

  .table_center {
    height: calc(100% - 60px);
    overflow-y: scroll;
  }
}

// 分页
.paging {
  padding: 10px 0;
  text-align: right;
}

// 新增课程
.new-courses {
  width: 100%;
  height: 68px;
  line-height: 68px;
  background-color: #f6f7fc;
  font-size: 16px;
  font-weight: 600;
  color: #1b1b1b;
  position: relative;
  text-indent: 36px;
}

.new-courses::after {
  content: "";
  width: 5px;
  height: 18px;
  background-color: #6340c8;
  border-radius: 2.5px;
  position: absolute;
  top: 26px;
  left: 25px;
}

::v-deep {
  .curriculum-search .el-input--medium .el-input__inner {
    min-width: 250px;
    // height: 50px;
    // line-height: 50px;
    background: #f6f7fc;
    border-radius: 10px;
    border: none;
  }

  .el-range-editor--medium.el-input__inner {


    background: #f6f7fc;
    border-radius: 10px;
    border: none;
  }

  .el-range-editor--medium .el-range-separator {}

  .el-date-editor .el-range-input {
    background: #f6f7fc;
  }

  .curriculum-search .el-form-item--medium .el-form-item__label {
    line-height: 50px;
  }

  .curriculum-search .el-form--inline .el-form-item {
    margin-right: 19px;
  }



}
</style>
<template>
  <section class="curriculum">
    <!-- <div class="curriculum-search">
      <el-form :inline="true" :model="searchform" class="demo-form-inline">
        <el-form-item label="用户名">
          <el-input v-model="searchform.shool" placeholder="审批人"></el-input>
        </el-form-item>

        <el-form-item label="时间">
          <el-date-picker
            v-model="searchform.shool"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" class="lightpurple-btn"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div> -->

    <div class="curriculum-table">
      <template>
        <div class="table_center">
          <el-table :data="tableData" style="width: 100%" :header-cell-style="{
            background: '#E8EAF3',
            color: '#1a1a1b',
            'text-align': 'center',
            fontWeight: 500,
          }">
            <el-table-column align="center" prop="auser_nickname" label="操作人" min-width="16%" />
            <el-table-column align="center" prop="alog_ip" label="IP" min-width="14%" />
            <el-table-column align="center" label="类型" min-width="16%">
              <template slot-scope="scope">
                {{ scope.row.alog_type == 1 && '登录' || scope.row.alog_type == 2 && '操作' || '系统运行监控' }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="alog_model" label="操作模型" min-width="12%" />
            <el-table-column align="center" label="请求类型" min-width="12%">
              <template slot-scope="scope">
                {{ scope.row.alog_reqtype == 1 && 'POST' || 'GET' }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="alog_url" label="请求地址" min-width="16%" />
            <el-table-column align="center" prop="create_time" label="操作时间" min-width="14%">
              <template slot-scope="scope">
                {{ scope.row.create_time * 1000 | formatTime }}
              </template>
            </el-table-column></el-table>
        </div>


        <div class="paging">
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
            next-text="下一页" layout=" pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { edulogList } from "@api/system";
export default {
  name: "",
  data() {
    return {
      currentPage: 1,
      total: 0,
      searchform: {
        pageindex: 1,
        pagesize: 10,
      },

      // 表格数据
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
      ],
    };
  },

  mounted() {
    // let doc = document.querySelector(".el-pagination");
    // let liElement = document.createElement("button");
    // liElement.setAttribute("class", "surebtn");
    // var liText = document.createTextNode("确定");
    // liElement.appendChild(liText);
    // doc.appendChild(liElement);
  },
  created() {
    this.init();
  },
  methods: {
    /**初始化 */
    async init() {
      const res = await edulogList(this.searchform);
      if (res.status == 200) {
        this.tableData = res.data.data;
        this.total = res.data.allcount
      }
    },
    // 修改当前
    handleCurrentChange() {
      this.searchform.pageindex = this.currentPage;
      this.init()
    },
  },
};
</script>
