import API from "@utils/request";

const EDULOG = "/education/edulog/";

/**
 * 日志列表
 * @param {object} params
 */
export async function edulogList(params) {
  try {
    return await API.get(EDULOG + "list", {
      params: {
        ...params,
        headers: { isLoading: true },
      },
    });
  } catch (error) {
    return error;
  }
}

